<template>
  <div>
    <CRow>
      <CCol col>
        <CCard>
          <CCardHeader>
            <strong> Calcular Precio Promedio - Productos</strong>
          </CCardHeader>
          <CCardBody>
            <b-row class="row justify-content-md-center">
              <b-col md="2">
                <b-form-group label="Hasta:">
                  <b-form-input type="date" v-model="to"></b-form-input>
                  <small v-if="errors.to" class="form-text text-danger"
                    >Seleccione un fecha</small
                  >
                </b-form-group>
              </b-col>

              <b-col md="2">
                <b-form-group label=".">
                  <b-button class="form-control" variant="primary" type="button" @click="CalculateAveragePrice">Calcular Precio Prom.</b-button>
                </b-form-group>
              </b-col>

              <b-col md="4">
              </b-col>

               <b-col md="3">
                  <b-form-group label=".">
                    <b-input-group>
                    <b-form-input v-model="search" class="form-control"></b-form-input>
                    <b-input-group-append>
                      <b-button variant="primary" @click="GetProductAveragePrice"><b-icon icon="search"></b-icon></b-button>
                    </b-input-group-append>
                  </b-input-group>
                  </b-form-group>
                </b-col>

                <b-col md="1">
                  <b-form-group label=".">
                    <b-button @click="ExportExcel" title="Exportar a Excel" type="button" variant="success" class="form-control"><i class="fas fa-file-excel"></i></b-button>
                  </b-form-group>
                </b-col>
            </b-row>
            

            <div class="table-responsive mt-3">
              <table class="table table-hover table-bordered">
                <thead>
                  <tr>
                    <th width="5%" class="text-center">#</th>
                    <th width="10%" class="text-center">Codigo</th>
                    <th width="45%" class="text-center">Nombre</th>
                    <th width="10%" class="text-center">Categoria</th>
                    <th width="10%" class="text-center">Marca</th>
                    <th width="10%" class="text-center">Precio <br> Promedio</th>
                    <th width="10%" class="text-center">Fecha Modificación</th>
                  </tr>
                </thead>
                <tbody v-for="(item, it) in products" :key="it">
                  <tr>
                    <td class="text-center">{{ it + 1 }}</td>
                    <td class="text-center"> {{ item.code }}</td>
                    <td class="text-left"> {{ item.name}}</td>
                    <td class="text-left"> {{ item.category_name }}</td>
                    <td class="text-center"> {{ item.brand_name }}</td>
                    <td class="text-right"> {{ item.average_price }}</td>
                    <td class="text-center"> {{ item.average_price_date }}</td>
                   
                  </tr>
                </tbody>
              </table>
            </div>
            <b-row class="mt-4">
              <b-col md="8">
                <b-pagination v-model="currentPage" v-on:input="GetProductAveragePrice" :total-rows="rows" :per-page="perPage" align="center"></b-pagination>
              </b-col>
              <b-col md="4 text-center">
                <p>Pagina Actual: {{ currentPage }}</p>
              </b-col>
            </b-row>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>

    <LoadingComponent :is-visible="isLoading" />
  </div>
</template>

<script>
const axios = require("axios").default;
const Swal = require("sweetalert2");
const je = require("json-encrypt");
import { mapState } from "vuex";

import 'regenerator-runtime/runtime';
var moment = require("moment");
import LoadingComponent from "./../pages/Loading";
export default {
  name: "RquirementList",
  components: {
    LoadingComponent,
  },
  data() {
    return {
      isLoading: false,
      module: "CalculateAveragePrice",
      to: moment(new Date()).local().format("YYYY-MM-DD"),
      
      batch_size: 100,
      lotes: [],

      perPage: 15,
      currentPage: 1,
      rows: 0,
      products: [],
      search: "",

      errors: {
        id_warehouse: false,
        to: false,
        from: false,
      },
    };
  },
  mounted() {
    this.GetProducts();
    this.GetProductAveragePrice();
  },
  methods: {
    GetProducts,
    GetProductAveragePrice,
    CalculateAveragePrice,
    ExportExcel,
  },

  computed: {
    ...mapState(["url_base"]),
    token: function () {
      let user = window.localStorage.getItem("user");
      user = JSON.parse(JSON.parse(je.decrypt(user)));
      return user.api_token;
    },
  },
};

function ExportExcel() {
  let search = this.search == "" ? "all" : this.search;
  let url = this.url_base + "excel-products-average-price/"+search;
  window.open(url,'_blank');
}

function GetProducts() {
  this.errors.to = false;
  if (this.to.length == 0) {
    this.errors.to = true;
    Swal.fire({
      icon: "warning",
      text: "Complete los cambos obligatorios",
      timer: 3000,
    });
    return false;
  }

  this.isLoading = true;
  let me = this;
  let url = this.url_base + "product/get-products";
  me.products = [];
  axios({
    method: "GET",
    url: url,
    headers: { token: this.token, module: this.module, role: 1 },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      let products = response.data.result;
      for (let i = 0; i < products.length; i += me.batch_size) {
        me.lotes.push(products.slice(i, i + me.batch_size));
      }
    }
    me.isLoading = false;
  })
  .catch((error) => {
    Swal.fire({ icon: "error", text: "A ocurrido un error", timer: 3000 });
    me.isLoading = false;
  });
}

function GetProductAveragePrice() {
  this.isLoading = true;
  let me = this;
  let url = this.url_base + "product/get-products-average-price";
  me.products = [];
 
  let data = {
    search : me.search == "" ? "all":me.search,
    page : me.currentPage,
  }
  axios({
    method: "POST",
    url: url,
    data: data,
    headers: { token: this.token, module: this.module, role: 1 },
  })
  .then(function (response) {
    if (response.data.status == 200) {
      me.rows = response.data.result.total;
      me.products = response.data.result.data;
      me.isLoading = false;
    }
    me.isLoading = false;
  })
  .catch((error) => {
    Swal.fire({ icon: "error", text: "A ocurrido un error", timer: 3000 });
    me.isLoading = false;
  });
}

async function CalculateAveragePrice() {
  let me = this;
  const lotes = this.lotes;
  console.log('num lotes : '+lotes.length)
  this.isLoading = true;
  var index = 1;
  for (const lote of lotes) {
    try {
      //  const response = await axios.get('https://jsonplaceholder.typicode.com/users');
      let url = this.url_base +"kardex/calculate-average-price-products";
      const response = await axios({
        method: "POST",
        url: url,
        data: { products : lote },
        headers: { token: this.token, module: this.module, role: 2},
      });
       console.log('Lote: '+index, response.data.result); // Laravel envía los productos paginados dentro de "data"
       index ++;
    } catch (error) {
      me.isLoading = false;
      console.error("Error al enviar el lote:", error);
    }
  }

  me.isLoading = false;
  me.GetProductAveragePrice();

}
</script>
